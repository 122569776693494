<template>
  <div>
    <b-overlay
      fixed
      :show="showen"
      z-index="300"
      no-wrap
      class="main-overlay"
      opacity="0.7"
      :no-center="true"
    >
      <template #overlay>
        <div class="show-post">
          <div class="side-post">
            <div class="top">
              <div class="logo">
                <img
                  :src="
                    require('@/assets/images/pages/classRoom/boards/posts/showPost/logoFS.png')
                  "
                  alt="logo-img"
                />
                <small v-if="data.title" class="text">
                  {{
                    data.list.title.split("").length >= 19
                      ? data.list.title.substring(0, 19)
                      : data.list.title
                  }}
                </small>
              </div>

              <div class="arrows">
                <div class="arrow" @click="prev" v-if="currentIndex != 0">
                  <img
                    :src="
                      require('@/assets/images/pages/classRoom/boards/posts/showPost/arrow.png')
                    "
                    alt=""
                  />
                </div>
                <span class="ml-1 mr-1"
                  >{{ currentIndex + 1 }}:{{ listPosts.length }}</span
                >
                <div
                  class="arrow"
                  @click="next"
                  v-if="currentIndex != listPosts.length - 1"
                >
                  <img
                    :src="
                      require('@/assets/images/pages/classRoom/boards/posts/showPost/arrow.png')
                    "
                    alt=""
                  />
                </div>
              </div>
              <div class="desc">
                <div class="teacher-info">
                  <img
                    :src="
                      data.user.media.length != 0
                        ? data.user.media[0].path
                        : require('@/assets/images/pages/classRoom/boards/posts/showPost/dummyPerson.png')
                    "
                    alt=""
                  />
                  <div class="text">
                    <span>
                      {{ data.user.first_name }} {{ data.user.middle_name }}
                      {{ data.user.last_name }}
                    </span>
                    <span> {{ timeConvertFunction(data.created_at) }} </span>
                  </div>
                </div>
                <div
                  class="course-info"
                  v-if="Array.isArray(data.media) && data.media.length != 0"
                >
                  <div>{{ data.title }}</div>
                  <div>
                    {{ data.text }}
                  </div>
                </div>
                <div class="comments-section">
                  <div class="dash"></div>
                  <div
                    class="comment"
                    v-for="comment of comments"
                    :key="comment.id"
                  >
                    <div class="info">
                      <img
                        style="object-fit: cover"
                        :src="
                          comment.user.media.length != 0 &&
                          $helpers.filterImages(
                            $helpers.uiAvatar(comment.user.first_name),
                            'path',
                            comment.user.media
                          )
                        "
                        :alt="comment.user.first_name"
                      />

                      <div class="text">
                        <span
                          >{{ comment.user.first_name }}
                          {{ comment.user.last_name }}
                          <spans
                            class="user-type"
                            v-if="comment.user.id === data.user.id"
                            >Creator</spans
                          ></span
                        >
                        <span>{{
                          timeConvertFunction(comment.created_at)
                        }}</span>
                      </div>
                      <b-dropdown
                        class="dropdwon"
                        variant="link"
                        toggle-class="text-decoration -none"
                        no-caret
                        right
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle"
                          />
                        </template>

                        <b-dropdown-item tag="li" @click="addReport(comment)">
                          <span>{{ $t("g.addReport") }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                      <div class="content">
                        {{ comment.comment }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="add-comment-form">
              <validation-observer ref="commentForm">
                <b-form>
                  <span class="d-flex col">
                    <img
                      style="object-fit: cover"
                      :src="
                        [...userData.media].length != 0
                          ? userData.media[0].path
                          : require('@/assets/images/pages/classRoom/boards/posts/showPost/dummyPerson.png')
                      "
                      alt=""
                    />
                  </span>
                  <span class="d-flex col-6 col">
                    <b-form-group>
                      <validation-provider rules="required">
                        <b-form-input
                          v-model="commentToSent"
                          :placeholder="$t('g.writeComment')"
                          class="input"
                        />
                      </validation-provider>
                    </b-form-group>
                  </span>
                  <span class="col mt-n5">
                    <img
                      :disabled="isLoading"
                      :src="
                        require('@/assets/images/pages/classRoom/boards/posts/showPost/paper-airplane.png')
                      "
                      alt="dummy"
                      @click="addComentREquest"
                    />
                  </span>
                </b-form>
              </validation-observer>
            </div>
            <overlay-component :isLoading="isLoading" />
          </div>
          <div class="preview-section">
            <b-row class="col-1 x-row">
              <img
                :src="
                  require('@/assets/images/pages/classRoom/boards/posts/showPost/x.png')
                "
                alt=""
                class="cursor-pointer"
                @click="close()"
              />
            </b-row>
            <b-row
              class="main-img-preview"
              v-if="Array.isArray(data.media) && data.media.length != 0"
            >
              <img class="main-img" :src="data.media[0].path" alt="" />
            </b-row>
            <b-row v-else>
              <div class="description-div">
                <div class="title">
                  {{ data.title }}
                </div>
                <div class="description">
                  {{ data.text }}
                </div>
              </div>
            </b-row>
          </div>
        </div>
      </template>
    </b-overlay>
    <report-component
      :reportable_id="report.reportable_id"
      :reportable_type="report.reportable_type"
    />
  </div>
</template>
<script>
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ReportComponent from "@/components/shared/ReportComponent/index.vue";
import {
  BCol,
  BRow,
  BOverlay,
  BFormGroup,
  BFormInput,
  BForm,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";

export default {
  name: "ShowPost",
  components: {
    BCol,
    BOverlay,
    BRow,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    ReportComponent,
    OverlayComponent,
  },
  data() {
    return {
      currentPostId: null,
      currentlistId: null,
      boardId: null,
      showen: true,
      currentIndex: null,
      listPosts: [],
      userData: {},
      data: {},
      comments: [],
      isLoading: false,
      commentToSent: null,
      page_data: {
        post_id: this.$route.params.postId,
        board_id: this.$route.params.boardId,
        list_id: this.$route.params.listId,
      },
      report: {
        show: false,
        reportable_id: null,
        reportable_type: null,
      },
    };
  },
  watch: {
    currentPostId(newval, oldVal) {
      this.getAllData();
    },
  },

  beforeMount() {
    this.currentPostId = this.page_data.post_id;
    this.boardId = this.page_data.board_id;
    this.currentlistId = this.page_data.list_id;
    this.getAllData();
    this.getMyData();
    this.getlistdata();
  },
  methods: {
    next() {
      if (this.currentIndex < this.listPosts.length - 1) {
        this.currentPostId = this.listPosts[this.currentIndex + 1].id;
      }
    },
    prev() {
      if (this.currentIndex > 0) {
        this.currentPostId = this.listPosts[this.currentIndex - 1].id;
      }
    },
    close() {
      this.$router.push({
        name: "Boards",
        params: { id: this.boardId },
      });
    },
    addComentREquest() {
      this.$refs.commentForm.validate().then((sucess) => {
        if (sucess) {
          this.isLoading = true;
          let form_data = new FormData();
          form_data.append("text", this.commentToSent);
          this.$http
            .post(
              `teacher/boards/${this.boardId}/lists/${this.currentlistId}/posts/${this.currentPostId}/comments`,
              form_data
            )
            .then((response) => {
              this.$helpers.makeToast(
                "success",
                this.$t("g.send.successTitle"),
                this.$t("g.commentAddedSucessfuly")
              );
              this.getAllData();
              this.commentToSent = null;
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              this.$helpers.handleError(error);
            });
        }
      });
    },
    getlistdata() {
      this.$http
        .get(`teacher/boards/${this.boardId}/lists/${this.currentlistId}/posts`)
        .then((response) => {
          this.listPosts = response.data.data;
          this.getCurrentPostIndex();
        })
        .catch((error) => console.log(error));
    },
    getMyData() {
      let newdata = JSON.parse(localStorage.getItem("userData"));
      this.userData = newdata;
    },
    getAllData() {
      this.$http
        .get(
          `teacher/boards/${this.boardId}/lists/${this.currentlistId}/posts/${this.currentPostId}`
        )
        .then((response) => {
          this.data = response?.data.data;
          this.getCurrentPostIndex();
          this.comments = response?.data.data.comments;
        });
    },
    getCurrentPostIndex() {
      // get current post index in list of post array
      const currentPostId = this.currentPostId;
      this.currentIndex = this.listPosts.findIndex(
        (e) => e["id"] == currentPostId
      );
    },
    Toast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: true,
      });
    },
    timeConvertFunction(time) {
      const timestampDate = new Date(time);
      const currentDate = new Date();
      const timeDifference = currentDate - timestampDate;
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} ago`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      } else {
        return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
      }
    },
    addReport(userData) {
      console.log(userData);
      this.report.reportable_id = userData.id;
      this.report.reportable_type = "comment";
      // console.log(this.$bvModal);
      this.$bvModal.show("bv-modal-report");
    },
  },
};
</script>

<style lang="scss" src="./_showPost.scss"></style>
